@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styles */
* {
	margin: 0;
	padding: 0;
	font-family: "Tajawal", sans-serif !important;
}

.table-description {
	-webkit-box-orient: vertical;

	-webkit-line-clamp: 2;

	display: -webkit-box;
	overflow: hidden;
	width: 100%;
}
